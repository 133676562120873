<script setup>

/**
 *  Imports
 */
import { useGlobalStore } from '@/store/global'

/**
 *  Composables
 */
const store = useGlobalStore()

const { isModalActive } = useCookieControl()

function handleCookies(){
    isModalActive.value = true;
}

</script>

<template>
    <Transition name="fade">
        <div v-if="store.getSidebarType == 'menu'" @click="toggleSidebar('menu')" :class="['bg-gray-100 z-50 opacity-80 fixed left-0 right-0 top-0 bottom-0 mx-auto']"></div>
    </Transition>

    <Transition name="slide-fade">
    <div v-if="store.getSidebarType == 'menu'" class="fixed text-black bg-white top-0 bottom-0 right-0 w-full md:w-96 overflow-auto text-center border-l z-50">

        <h2 class="my-4 uppercase text-xs text-gray-700 relative">
            <svg @click="toggleSidebar('menu')" class="absolute lg:hidden cursor-pointer left-2 text-gray-400 top-0 bottom-0 my-auto w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
            </svg>
            Menu
        </h2>

        <NuxtLink class="block p-2 cursor-pointer hover:text-blue-700" @click="handleCookies(), toggleSidebar('menu')">
            Nastavenia cookies
        </NuxtLink>

        <NuxtLink to="/verse/ako-to-funguje" :class="['block p-2 cursor-pointer', $route.name == 'verse' ? 'text-blue-700' : 'hover:text-blue-700']" @click="toggleSidebar('menu')">
            Verše plugin
        </NuxtLink>

        <NuxtLink to="/clanky/prve-preklady-biblie-do-slovenciny" :class="['block p-2 cursor-pointer', $route.name == 'clanky' ? 'text-blue-700' : 'hover:text-blue-700']" @click="toggleSidebar('menu')">
            O Biblii na Slovensku
        </NuxtLink>

        <hr class="my-4">

        <NuxtLink to="https://www.eshop.biblia.sk/" target="_blank" class="block p-2 cursor-pointer hover:text-blue-700" @click="toggleSidebar('menu')">
            E-shop
        </NuxtLink>

        <NuxtLink to="https://audio.biblia.sk/" target="_blank" class="block p-2 cursor-pointer hover:text-blue-700" @click="toggleSidebar('menu')">
            Audio Biblia
        </NuxtLink>

        <NuxtLink to="https://www.biblickaspolocnost.sk/" target="_blank" class="block p-2 cursor-pointer hover:text-blue-700" @click="toggleSidebar('menu')">
            Slovenská biblická spoločnosť
        </NuxtLink>

        <NuxtLink to="https://www.bibliaprenepocujucich.sk/" target="_blank" class="block p-2 cursor-pointer hover:text-blue-700" @click="toggleSidebar('menu')">
            Biblia pre nepočujúcich
        </NuxtLink>
        
        <hr class="my-4">

        <NuxtLink to="https://www.facebook.com/SlovenskaBiblickaSpolocnost/" target="_blank" class="block p-2 cursor-pointer hover:text-blue-700" @click="toggleSidebar('menu')">
            <svg class="w-6 inline-block mr-2 text-blue-600" fill="currentColor" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title>Facebook</title><path d="M24 12.073c0-6.627-5.373-12-12-12s-12 5.373-12 12c0 5.99 4.388 10.954 10.125 11.854v-8.385H7.078v-3.47h3.047V9.43c0-3.007 1.792-4.669 4.533-4.669 1.312 0 2.686.235 2.686.235v2.953H15.83c-1.491 0-1.956.925-1.956 1.874v2.25h3.328l-.532 3.47h-2.796v8.385C19.612 23.027 24 18.062 24 12.073z"/></svg>
            Facebook
        </NuxtLink>

        <NuxtLink to="https://www.youtube.com/channel/UCYLTL_jk6T0KyTId5zInnKw" target="_blank" class="block p-2 cursor-pointer hover:text-blue-700" @click="toggleSidebar('menu')">
            <svg class="w-6 inline-block mr-2 text-red-600" fill="currentColor" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title>YouTube</title><path d="M23.498 6.186a3.016 3.016 0 0 0-2.122-2.136C19.505 3.545 12 3.545 12 3.545s-7.505 0-9.377.505A3.017 3.017 0 0 0 .502 6.186C0 8.07 0 12 0 12s0 3.93.502 5.814a3.016 3.016 0 0 0 2.122 2.136c1.871.505 9.376.505 9.376.505s7.505 0 9.377-.505a3.015 3.015 0 0 0 2.122-2.136C24 15.93 24 12 24 12s0-3.93-.502-5.814zM9.545 15.568V8.432L15.818 12l-6.273 3.568z"/></svg>
            YouTube
        </NuxtLink>
        
        <hr class="mt-4">

        <button @click="toggleSidebar('menu')" class="my-6 text-gray-500 hover:text-black">Zatvoriť</button>

    </div>
    </Transition>

</template>