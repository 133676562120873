<script setup>

import { useGlobalStore } from '@/store/global'
defineProps(['loaded'])

const store = useGlobalStore()

const homeUrl = computed(() => {
    return `/citanie/${store.getSelectedTranslation.identifier}/${store.getSelectedBook.abbreviation}/${store.getSelectedChapter}`
})

</script>
<template>

    <div v-if="loaded" id="header" class="bg-primary-bg text-primary-fg flex items-stretch border-b border-blue-800">

        <Logo :homeUrl="homeUrl"/>

        <MainNavbar :homeUrl="homeUrl"/>

        <!--<ThemeToggle />-->

        <WhatsNewButton/>
        <WhatsNewSidebar/>
        
        <MenuSidebarButton/>
        <MenuSidebar/>

    </div>

</template>