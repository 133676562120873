<script setup>

import { useGlobalStore } from '@/store/global'
import items from '@/static/whatsnew'

const store = useGlobalStore()
const filteredItems = computed(() => {
    return items.filter((item) => item.status > 0)
})

</script>
<template>
    <Transition name="fade">
        <div v-if="store.getSidebarType == 'whatsnew'" @click="toggleSidebar('whatsnew')" :class="['bg-gray-100 z-50 opacity-80 fixed left-0 right-0 top-0 bottom-0 mx-auto']"></div>
    </Transition>

    <Transition name="slide-fade">
    <div v-if="store.getSidebarType == 'whatsnew'" class="fixed text-black bg-white top-0 bottom-0 right-0 w-full md:w-96 overflow-auto text-center border-l z-50">

        <h2 class="my-4 uppercase text-xs text-gray-700 relative">
            <svg @click="toggleSidebar('whatsnew')" class="absolute lg:hidden cursor-pointer left-2 text-gray-400 top-0 bottom-0 my-auto w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
            </svg>
            Čo je nové
        </h2>

        <div class="border-t text-left" v-for="item in filteredItems">
            <div class="p-4">
                <span class="bg-slate-100 px-2 rounded border mb-1 inline-block text-sm">{{ item.date }}</span>
                <span v-if="item.type == 'bug'" class="bg-red-500 text-white px-2 rounded ml-2 inline-block border border-red-600 text-sm">oprava chýb</span>
                <span v-if="item.type == 'feature' && item.status == 2" class="bg-green-500 text-white px-2 rounded ml-2 inline-block border border-green-600 text-sm">nové</span>
                <h2 class="font-bold mb-2">{{ item.title }}</h2>
                <p class="prose text-gray-700 prose-md prose-lead:3" v-html="item.description"></p>
            </div>
        </div>

        <hr class="my-4">
    
        <button @click="toggleSidebar('whatsnew')" class="my-6 text-gray-500 hover:text-black">Zatvoriť</button>

    </div>
    </Transition>

</template>