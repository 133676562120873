<script setup>

defineProps(['homeUrl'])

const route = useRoute();

</script>
<template>
    <div class="md:mx-4 flex-1 flex">
        <NuxtLink :to="homeUrl" :class="['relative uppercase py-4 px-3 md:px-4 inline-block', route.name === 'citanie' ? 'bg-blue-800/75' : 'hover:bg-blue-600']">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 inline-block">
                <path stroke-linecap="round" stroke-linejoin="round" d="M17.593 3.322c1.1.128 1.907 1.077 1.907 2.185V21L12 17.25 4.5 21V5.507c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0111.186 0z" />
            </svg>
            <span class="ml-2 hidden lg:inline-block align-middle">Čítať</span>
        </NuxtLink>

        <NuxtLink to="/vyhladavanie" :class="['relative uppercase py-4 px-3 md:px-4 inline-block', route.name === 'vyhladavanie' ? 'bg-blue-800/75' : 'hover:bg-blue-600']">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 inline-block">
                <path stroke-linecap="round" stroke-linejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
            </svg>
            <span class="ml-2 hidden lg:inline-block align-middle">Hľadať</span>
        </NuxtLink>

        <NuxtLink to="https://www.eshop.biblia.sk/" :class="['relative uppercase py-4 px-3 md:px-4 inline-block hover:bg-blue-600']" target="_blank">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 inline-block">
                <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 00-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 00-16.536-1.84M7.5 14.25L5.106 5.272M6 20.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zm12.75 0a.75.75 0 11-1.5 0 .75.75 0 011.5 0z" />
            </svg>
            <span class="ml-2 hidden lg:inline-block align-middle">Nakupovať</span>
        </NuxtLink>

        <NuxtLink to="https://biblickaspolocnost.darujme.sk/biblia-sk/?referral_tag_id=aaca1abc-5767-4cc7-8c78-7b8fe1455ca9" :class="['relative uppercase py-4 px-3 md:px-4 inline-block hover:bg-blue-600']" target="_blank">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 inline-block">
                <path stroke-linecap="round" stroke-linejoin="round" d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12z" />
            </svg>
            <span class="ml-2 hidden lg:inline-block align-middle">Podporiť</span>
        </NuxtLink>

    </div>
</template>