<script setup>

import { useGlobalStore } from '@/store/global'
import items from '@/static/whatsnew'

const store = useGlobalStore()
const newsNumber = computed(() => {
    return items.filter((item) => item.status === 2)
})
const bibliaSk = useCookie("biblia_sk", {
    maxAge: 31546000
})

function handleWhatsNewClick(){
    if(bibliaSk.value != undefined){
        bibliaSk.value.whatsNew = true
    }
}

</script>
<template>
    <button @click="toggleSidebar('whatsnew'); handleWhatsNewClick()" :class="['relative py-4 px-3 md:px-4 inline-block hover:bg-blue-600', store.getSidebarType == 'announcements' ? 'bg-blue-600' : '']">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 inline-block">
            <path stroke-linecap="round" stroke-linejoin="round" d="M10.34 15.84c-.688-.06-1.386-.09-2.09-.09H7.5a4.5 4.5 0 110-9h.75c.704 0 1.402-.03 2.09-.09m0 9.18c.253.962.584 1.892.985 2.783.247.55.06 1.21-.463 1.511l-.657.38c-.551.318-1.26.117-1.527-.461a20.845 20.845 0 01-1.44-4.282m3.102.069a18.03 18.03 0 01-.59-4.59c0-1.586.205-3.124.59-4.59m0 9.18a23.848 23.848 0 018.835 2.535M10.34 6.66a23.847 23.847 0 008.835-2.535m0 0A23.74 23.74 0 0018.795 3m.38 1.125a23.91 23.91 0 011.014 5.395m-1.014 8.855c-.118.38-.245.754-.38 1.125m.38-1.125a23.91 23.91 0 001.014-5.395m0-3.46c.495.413.811 1.035.811 1.73 0 .695-.316 1.317-.811 1.73m0-3.46a24.347 24.347 0 010 3.46" />
        </svg>
        <span class="ml-2 hidden lg:inline-block align-middle">Čo je nové</span>
        <span v-if="!bibliaSk.whatsNew" class="font-['Open_Sans'] font-bold absolute top-[10px] left-2 ml-1 bg-green-400 rounded-full w-4 h-4 text-xs">
            <span class="align-top absolute -top-[0.5px] left-0 right-0 m-auto">{{newsNumber.length}}</span>
        </span>
    </button>
</template>