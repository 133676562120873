<script setup>

import { ref } from 'vue'

const loaded = ref(false)

onMounted(() => {
    loaded.value = true
})

</script>
<template>
    <div>

        <Background :loaded="loaded"/>
        
        <Message/>

        <ClientOnly>
            <Donation/>
        </ClientOnly>

        <!--
        <ClientOnly>
            <NoticeToolbar/>
        </ClientOnly>
        -->
                
        <ClientOnly>
            <Header :loaded="loaded"/>
        </ClientOnly>

        <ClientOnly>
            <PodporaButton/>
        </ClientOnly>
        <slot />

    </div>
</template>